import React, { useState } from 'react';
import firebase from './firebase';
import "./Review.css"

const ReviewForm = () => {
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [rating, setRating] = useState(0);
    const [showMessage, setShowMessage] = useState(false); // State to manage message visibility

    const handleSubmit = (e) => {
        e.preventDefault();

        // Push data to Firebase
        const reviewsRef = firebase.database().ref('reviews');
        reviewsRef.push({
            name: name,
            phoneNumber: phoneNumber,
            message: message,
            rating: rating
        });

        // Reset form fields after submission
        setName('');
        setPhoneNumber('');
        setMessage('');
        setRating(0);

        // Show message for 5 seconds
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 5000);
    };

    const handleRatingChange = (newRating) => {
        setRating(newRating);
    };

    const renderStars = () => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <span
                    key={i}
                    onClick={() => handleRatingChange(i)}
                    style={{ cursor: 'pointer' }}
                >
                    {i <= rating ? '★' : '☆'}
                </span>
            );
        }
        return stars;
    };

    return (
        <div className="review-container">
            {showMessage && (
                <div className="success-message">
                    Thanks for reviewing us ❤️!
                </div>
            )}
            <h1 className="review-heading">Review Us</h1>
            <form className="review-form" onSubmit={handleSubmit}>
                <label>
                    Name:
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    Phone Number:
                    <input
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    Message:
                    <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    Rating: {renderStars()}
                </label>
                <br />
                <button type="submit">Submit Review</button>
            </form>
        </div>
    );
};

export default ReviewForm;
