import React from 'react';
import { Link } from 'react-router-dom';
import "./GeneralService.css";
import acImage from "./ac.jpg"; // Import the image
import Services from '../Services';

class ACServiceDetail extends React.Component {
  render() {
    return (
      <div className="general-service-container">
        <img src={acImage} alt="Air Conditioner" className="general-service-image" />
        <h1 className="general-service-title">AC Servicing</h1>
        <p className="general-service-text">
          Our AC servicing includes a thorough inspection and maintenance to ensure your air conditioner operates efficiently. Key aspects of our AC servicing include:
        </p>
        <ul className="general-service-list">
          <li>Checking and cleaning air filters</li>
          <li>Inspecting and cleaning condenser coils</li>
          <li>Checking refrigerant levels and topping up if necessary</li>
          <li>Inspecting and lubricating fan motors</li>
          <li>Checking thermostat operation</li>
          <li>Inspecting electrical connections</li>
          <li>And more...</li>
        </ul>
        <p className="general-service-text">
          Our experienced technicians ensure that your AC system is in top condition, providing you with a comfortable indoor environment.
        </p>
        <h2 className="general-service-subtitle">Why Regular AC Servicing is Important</h2>
        <p className="general-service-text">
          Regular AC servicing improves energy efficiency, extends the lifespan of your system, and ensures better air quality by removing dust and allergens.
        </p>
        <Link to="/Bookservice">
          <button className="general-service-button">Book Appointment</button>
        </Link>
        

<Services />
      </div>
    );
  }
}

export default ACServiceDetail;
