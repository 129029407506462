import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import Routes
import './App.css';
import Navbar from './Navbar';
import Home from './Home';
import Review from './Reviewfor'
import Footer from './Footer';
import Book from './booking';
import AboutUs from './About';
import Services from './Services';
import Admin from './Admin';
import Reviews from './adminreview';
import Bookings from './adminbook';

import GeneralService from './Blogs/GeneralService';
// Import your loading animation component
import AC from './Blogs/Ac';
import Wheel from './Blogs/Wheel';
import Battery from './Blogs/Battery';
import Brake from './Blogs/Brake';
import Engine from './Blogs/Engine';
import Electrical from './Blogs/Electrical';
import ServicePage from './ServicesHome';
import AboutSection from './AboutHome';
import Terms from './TermsAndConditions';
import Check from './Check';
import Return from './Return';
import Privacy from './Privacy';
function App() {

  return (
    <Router>
        <div className="App">

        <Navbar />
      
     
          <Routes> 
            <Route path="/" element={<Home />} /> 
            <Route path="/reviews" element={<Review />} /> 
            <Route path="/Bookservice" element={<Book />} />
            <Route path='/About us' element={<AboutUs />}/>
            <Route path='/AboutUs' element={<AboutSection />}/>
            <Route path='/Services' element={<Services />}/>
            <Route path='/Service' element={<ServicePage />}/>
            <Route path='/admin/*' element={<Admin />}/>
            <Route path="/admin/reviews/*" element={<Reviews />} />
            <Route path="/admin/bookings/*" element={<Bookings />} />
            <Route path="/Services/1" element={<GeneralService/>} />
            <Route path="/Services/5" element={<AC/>} />
            <Route path="/Services/2" element={<Wheel/>} />
            <Route path="/Services/4" element={<Battery/>} />
            <Route path="/Services/3" element={<Brake/>} />
            <Route path="/Services/6" element={<Engine/>} />
            <Route path="/Services/7" element={<Electrical/>} />
       <Route path="/terms-and-conditions" element={<Terms/>} />
  <Route path="/privacy-policy" element={<Privacy/>} />
  <Route path="/Pricing-checkout-flow" element={<Check/>} />
  <Route path="/Refund-policy" element={<Return/>} />
            <Route path="/admin/bookings/*" element={<Bookings />} />
       
          </Routes>
        
        <Footer />
    </div>
      </Router>
  );
}

export default App;
