import React from 'react';
import { Link } from 'react-router-dom';
import "./GeneralService.css";
import batteryCheckImage from "./battery.jpg"; // Import the image
import Services from '../Services';

class BatteryCheckReplacementDetail extends React.Component {
  render() {
    return (
      <div className="general-service-container">
        <img src={batteryCheckImage} alt="Battery Check and Replacement Service" className="general-service-image" />
        <h1 className="general-service-title">Battery Check and Replacement Service</h1>
        <p className="general-service-text">
          Our battery check and replacement service ensures that your vehicle's battery is in good condition and provides reliable starting power. Some key aspects of our battery service include:
        </p>
    
        <h3>Testing battery voltage and health</h3>
        <h3>Inspecting battery terminals and connections</h3>
        <h3>Checking battery fluid levels (if applicable)</h3>
        <h3>Testing charging system</h3>
        <h3>Replacing old or faulty batteries</h3>
        <h3>And more...</h3>
      
        <p className="general-service-text">
          Our experienced technicians help you choose the right battery for your vehicle and ensure proper installation, providing you with peace of mind and reliable starting power.
        </p>
        <h2 className="general-service-subtitle">Why Battery Check and Replacement is Important</h2>
        <p className="general-service-text">
          Regular battery checks prevent unexpected battery failures and ensure reliable vehicle starting in all weather conditions. Timely battery replacement prevents vehicle breakdowns and inconvenience.
        </p>
        <Link to="/Bookservice">
          <button className="general-service-button">Book Battery Service</button>
        </Link>
        
        <Services />
      </div>
    );
  }
}

export default BatteryCheckReplacementDetail;
