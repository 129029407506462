import React, { useState, useEffect } from 'react';
import firebase from './firebase';
import './AdminBookings.css'; // Import your CSS file for styling
import Admin from './Admin';

const AdminBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true); // State variable for loading indicator

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const snapshot = await firebase.database().ref('orders').once('value');
        const bookingsData = snapshot.val();
        if (bookingsData) {
          const bookingsArray = Object.keys(bookingsData).map(key => ({
            id: key,
            ...bookingsData[key]
          }));
          setBookings(bookingsArray);
        }
      } catch (error) {
        console.error('Error fetching bookings:', error);
      } finally {
        setLoading(false); // Set loading to false when data fetching is complete
      }
    };
    fetchBookings();
  }, []);



  return (
    <div className="admin-bookings-page">
      <Admin />
      <div className="admin-bookings-container">
        <h2>Bookings</h2>
        {loading ? ( // Display loading spinner if data is still loading
          <div className="spinner"></div>
        ) : (
          <table className="bookings-table">
            <thead>
              <tr>
                {/* <th>Booking ID</th> */}
                <th>Mobile</th>
                <th>Vehicle Number</th>
                <th>Brand</th>
                <th>Model</th>
                <th>Address</th>
                {/* <th>Status</th> */}
                <th>Booked Date</th>
                {/* <th>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {bookings.map(booking => (
                <tr key={booking.id}>
                  {/* <td>{booking.id}</td> */}
                  <td>{booking.mobile}</td>
                  <td>{booking.vehicleNumber}</td>
                  <td>{booking.brand}</td>
                  <td>{booking.model}</td>
                  <td>{booking.address}</td>
                  {/* <td className={booking.status === 'completed' ? 'completed-status' : ''}>{booking.status}</td> */}
                  <td>{booking.dateOfBooking}</td>
                  {/* <td>
                    {booking.status !== 'completed' && (
                      <button className="completed-button" onClick={() => handleStatusUpdate(booking.id)}>Completed</button>
                    )}
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default AdminBookings;
