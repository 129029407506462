import React, { useState, useEffect } from 'react';
import { Link, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import firebase from './firebase';
import Reviews from './adminreview';
import Bookings from './adminbook';
import "./admin.css";

const Admin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      navigate('/admin/reviews');
    } catch (error) {
      // console.error(error.message);
      setErrorMessage("Invalid email or password"); // Set error message state
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      await firebase.auth().signOut();
      navigate('/admin');
    } catch (error) {
      // console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const openWhatsApp = () => {
    window.open('https://wa.me/919001270298', '_blank');
  };
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  if (loading || isLoading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }
  
  if (!user) {
    return (
      <div className="admin-login-container">
        <div className="admin-login">
          <h2> Admin Login</h2>
          {errorMessage && <p className="error-message red-text">{errorMessage}</p>}

          <form onSubmit={handleLogin}>
            <div>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button type="submit">Login</button>
          </form>
          <p>Powered by YespCorp</p>
        </div>
      </div>
    );
  }

  return (
    <div className="admin-container">
      <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <h2>Helloooo 👋🏻 Admin</h2>
          <button className="toggle-button" onClick={toggleSidebar}>
            ☰
          </button>
        </div>
        <ul className="sidebar-nav">
          <li>
            <Link to="/admin/reviews">Reviews</Link>
          </li>
          <li>
            <Link to="/admin/bookings">Bookings</Link>
          </li>
        
          <li>
            <Link onClick={openWhatsApp}>Help @ Yesp🪽</Link>
          </li>
          <li>
            <button onClick={handleLogout}>Logout</button>
          </li>
        </ul>
      </div>
      {sidebarOpen && (
        <button className="open-toggle-button" onClick={toggleSidebar}>
          ☰
        </button>
      )}
      {user && ( // Check if user is logged in before rendering routes
        <div className="content">
          <Routes>
            <Route path="/admin/reviews/*" element={<Reviews />} />
            <Route path="/admin/bookings/*" element={<Bookings />} />

            {/* Redirect to login page if trying to access any other admin route */}
            <Route path="/admin/" element={<Navigate to="/admin" />} />
          </Routes>
        </div>
      )}
    </div>
  );
};

export default Admin;
