import React from 'react';

const TermsAndConditions = () => {
  const styles = {
    termsAndConditions: {
      padding: '20px',
      backgroundColor: '#f9f9f9',
    },
    container: {
      maxWidth: '800px',
      margin: '0 auto',
    },
    heading1: {
      color: '#333',
    },
    heading2: {
      color: '#333',
    },
    paragraph: {
      color: '#666',
      lineHeight: '1.6',
    },
  };

  return (
    <div style={styles.termsAndConditions}>
      <div style={styles.container}>
        <h1 style={styles.heading1}>Terms and Conditions</h1>
        <p style={styles.paragraph}>
          Welcome to Siragirivel Automobiles. Please read these terms and conditions carefully before using our services.
        </p>

        <h2 style={styles.heading2}>1. Introduction</h2>
        <p style={styles.paragraph}>
          By accessing or using our services, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you must not use our services.
        </p>

        <h2 style={styles.heading2}>2. Services</h2>
        <p style={styles.paragraph}>
          Siragirivel Automobiles provides a range of automotive services, including routine maintenance, repairs, and other automotive solutions. The specific services provided will be outlined in our service agreements.
        </p>

        <h2 style={styles.heading2}>3. User Responsibilities</h2>
        <p style={styles.paragraph}>
          You agree to use our services for lawful purposes only. You must not use our services in any way that breaches any applicable local, national, or international law or regulation.
        </p>

        <h2 style={styles.heading2}>4. Payments</h2>
        <p style={styles.paragraph}>
          All payments for our services must be made in accordance with the payment terms specified in our service agreements. Failure to make timely payments may result in the suspension or termination of services.
        </p>

        <h2 style={styles.heading2}>5. Limitation of Liability</h2>
        <p style={styles.paragraph}>
          To the maximum extent permitted by law, Siragirivel Automobiles shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use our services; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein.
        </p>

        <h2 style={styles.heading2}>6. Changes to Terms</h2>
        <p style={styles.paragraph}>
          We reserve the right to modify these terms and conditions at any time. We will notify you of any changes by posting the new terms and conditions on our website. Your continued use of our services after such changes have been posted constitutes your acceptance of the new terms and conditions.
        </p>

        <h2 style={styles.heading2}>7. Governing Law</h2>
        <p style={styles.paragraph}>
          These terms and conditions are governed by and construed in accordance with the laws of the jurisdiction in which Siragirivel Automobiles operates, without regard to its conflict of law principles.
        </p>

        <h2 style={styles.heading2}>8. Contact Us</h2>
        <p style={styles.paragraph}>
          If you have any questions about these terms and conditions, please contact us at admin@siragirivel.in .
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
