import React from 'react';
import { Link } from 'react-router-dom';
import "./Blogs/GeneralService.css";
import Services from './Services';
// import mytvsImage from "./mytvs.jpg"; // Import the MyTVS logo

class ServicePage extends React.Component {
  render() {
    return (
      <div className="service-page-container">
        <div className="service-header">
          {/* <img src={mytvsImage} alt="MyTVS Logo" className="service-logo" /> */}
          <h1 className="service-title">Welcome to MyTVS Service Center</h1>
          <p className="service-description">
            At MyTVS, we are committed to providing top-quality service for your vehicle. Our skilled technicians use advanced tools and techniques to ensure your vehicle is in peak condition.
          </p>
        </div>
        <div className="service-content">
        
         
        </div>
        <div className="service-footer">
        <Link to="/Bookservice">
            <button className="service-button">Book Service</button>
          </Link>
          <Services />
        </div>
      </div>
    );
  }
}

export default ServicePage;
