
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';

const firebaseConfig = {
    apiKey: "AIzaSyAkG63HA6B40gKyxt9EuwP77s_FWsvY3hU",
    authDomain: "siragiri-f699f.firebaseapp.com",
    projectId: "siragiri-f699f",
    storageBucket: "siragiri-f699f.appspot.com",
    messagingSenderId: "443927662076",
    appId: "1:443927662076:web:452fe802249c947be3b679",
    measurementId: "G-1RXB4ZP42R"
  };

  firebase.initializeApp(firebaseConfig);
  

  export default firebase;