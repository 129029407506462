import React, { useState, useEffect } from 'react';
import logo from './mytvs.png'; // Import your logo image here
import './Navbar.css'; // Import your CSS file for styling

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setIsOpen(false); // Close the menu on resize
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
      
        <img src={logo} alt="Logo" className="navbar-logo" />
        <span className="brand-text">| Siragirivel Automobiles</span>
      </div>
      {/* Use a conditional rendering to show/hide the toggle button based on screen size */}
      {windowWidth <= 768 && (
        <button className="toggle-button" onClick={toggleMenu}>
          {/* Use an icon or text for the toggle button */}
          ☰
        </button>
      )}
      {/* Use a conditional rendering to show/hide the navigation links based on the toggle button state */}
      {(windowWidth > 768 || isOpen) && (
        <div className={`navbar-links ${windowWidth <= 768 && isOpen ? 'open' : ''}`}>
          <a href="/" className="navbar-item">Home</a>
          <a href="/AboutUs" className="navbar-item">About Us</a>
          <a href="/Service" className="navbar-item">Our Services</a>
          <a href="/testimonials" className="navbar-item">Testimonials</a>
          <a href="/reviews" className="navbar-item">Review Us</a>
          <a href="/Bookservice" className="navbar-item">Book Service</a>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
