import React, { useState, useEffect } from 'react';
import firebase from './firebase';
import "./AdminReviews.css"; // Import your CSS file for styling
import Admin from './Admin';

const AdminReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true); // State variable for loading indicator

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const snapshot = await firebase.database().ref('reviews').once('value');
        const reviewsData = snapshot.val();
        if (reviewsData) {
          const reviewsArray = Object.keys(reviewsData).map(key => ({
            id: key,
            ...reviewsData[key]
          }));
          setReviews(reviewsArray);
        }
      } catch (error) {
        // console.error('Error fetching reviews:', error);
      } finally {
        setLoading(false); // Set loading to false when data fetching is complete
      }
    };
    fetchReviews();
  }, []);

  return (
    <div className="admin-reviews-page">
      <Admin />
      <div className="admin-reviews-container">
        <h2>Reviews</h2>
        {loading ? ( // Display loading spinner if data is still loading
          <div className="spinner"></div>
        ) : (
          <table className="reviews-table">
            <thead>
              <tr>
                <th>Phone Number</th>
                <th>Name</th>
                <th>Comment</th>
                <th>Rating</th>
                {/* Add more table headings as needed */}
              </tr>
            </thead>
            <tbody>
              {reviews.map(review => (
                <tr key={review.id}>
                  <td>{review.phoneNumber}</td>
                  <td>{review.name}</td>
                  <td>{review.message}</td>
                  <td>{review.rating}</td>
                  {/* Add more table cells for additional review details */}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default AdminReviews;
