
import './App.css';

import Booking from './booking';
import AboutUs from './About';

import Services from './Services';
import Price from './price';

function App() {
  return (
    <div className="App">
   
    <Booking />
    <AboutUs />
    {/* <Products /> */}
    <Services />
    <br></br>
    <br></br>
    <Price />

  
    </div>
  );
}

export default App;
