import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Service.css'; // Import the CSS file
import LoadingAnimation from './LoadingAnimation'; // Import your loading animation component

// Import images for products or services
import product1Image from './Blogs/genser.jpg';
import product2Image from './Blogs/wheel.jpg';
import product3Image from './Blogs/braking.jpg';
import product4Image from './Blogs/battery.jpg';
import product5Image from './Blogs/ac.jpg';
import product6Image from './Blogs/engine.jpg';
import product7Image from './Blogs/electrical.jpg';

const SiragirivelCarousel = () => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // Simulate loading data with a delay
    const timer = setTimeout(() => {
      const data = [
        { id: 1, name: "GeneralService", image: product1Image, description: "Regular maintenance checks, including oil change, filter replacement, fluid top-ups, etc." },
        { id: 2, name: "Wheel Alignment and Balancing", image: product2Image, description: "Ensuring proper alignment and balance of wheels for optimal performance and safety." },
        { id: 3, name: "Brake System Services", image: product3Image, description: "Inspection, repair, or replacement of brake pads, discs, calipers, and other brake components." },
        { id: 4, name: "Battery Check and Replacement", image: product4Image, description: " Testing battery health and replacing if necessary" },
        { id: 5, name: "AC Service and Repair", image: product5Image, description: "Diagnosis and repair of air conditioning systems, including recharging refrigerant" },
        { id: 6, name: "Engine Diagnostics", image: product6Image, description: "Using diagnostic tools to identify and rectify engine-related issues" },
        { id: 7, name: "Electrical System Services", image: product7Image, description: "Troubleshooting and repair of electrical components, including alternators, starters, wiring, etc" },
      ];
      setProducts(data);
      setLoading(false);
    }, 100);

    // Clear the timeout on component unmount to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);

  // Function to handle clicking on the product
  const handleProductClick = (id) => {
    window.location.href = `/Services/${id}`;
  };

  // Settings for the carousel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  return (
    <div className="siragirivel-carousel-container">
      <h3>Explore our Services</h3>
      {loading ? (
        <LoadingAnimation />
      ) : (
        <Slider {...settings}>
          {products.map((product, index) => (
            <div key={index} className="product-slide">
              {/* Use a div or a button instead of an anchor */}
              <div className="product-link" onClick={() => handleProductClick(product.id)}>
                <div className="product-box">
                  <img src={product.image} alt={product.name} className="product-image" />
                  <h3>{product.name}</h3>
                  <p>{product.description}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default SiragirivelCarousel;
