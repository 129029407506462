import React from 'react';

const RefundPolicy = () => {
  const styles = {
    section: {
      padding: '20px',
      backgroundColor: '#f9f9f9',
    },
    container: {
      maxWidth: '800px',
      margin: '0 auto',
    },
    heading1: {
      color: '#333',
    },
    paragraph: {
      color: '#666',
      lineHeight: '1.6',
    },
    contactDetails: {
      color: '#333',
      lineHeight: '1.6',
      marginTop: '20px',
    },
  };

  return (
    <div style={styles.section}>
      <div style={styles.container}>
        <h1 style={styles.heading1}>Refund Policy</h1>
        <p style={styles.paragraph}>
          Siragirivel Automobiles is committed to ensuring customer satisfaction. Although we do not have a specific refund policy for the services provided, we value your feedback and are dedicated to resolving any concerns you may have. If you encounter any issues with our services, please reach out to us directly, and we will work with you to find a suitable solution.
        </p>
        <p style={styles.paragraph}>
          For more details or assistance regarding refunds or service-related matters, please contact Siragirivel Automobiles at:
        </p>
        <p style={styles.contactDetails}>
          Phone: 099433 08008<br />
          Address: 330/1, Erode to Perundurai Main Road, Post, Vallipurathanpalayam, Erode, Tamil Nadu 638112
        </p>
      </div>
    </div>
  );
};

export default RefundPolicy;
