import React from "react";
import './About.css';
import aboutImage from './img.png'; // Import the image
import Services from "./Services";

const AboutSection = () => {
  return (
    <section id="about" className="about-section">
      <div className="container">
        <div className="section-title">
          <h2>About Us</h2>
        </div>
        <div className="about-content">
          <div className="about-text">
            <h2>Welcome to Siragirivel Automobiles!</h2>
            <p>
              At Siragirivel, we pride ourselves on delivering exceptional automotive services. Our collaboration with MyTVS, a premier automotive solutions provider, allows us to offer a comprehensive range of services to meet your needs.
            </p>
            <p>
              Through our partnership, we strive to deliver unparalleled quality and convenience. Whether you require routine maintenance, repairs, or other automotive solutions, our team is dedicated to exceeding your expectations.
            </p>
            <p>
              Thank you for choosing Siragirivel Automobiles. We look forward to serving you and providing top-notch automotive care.
            </p>
          </div>
          <div className="about-image">
            <img src={aboutImage} alt="About Us" /> {/* Use the imported image */}
          </div>
        </div>
      </div>

      {/* Links Section */}
 

      <Services />
      
    </section>
  );
};

export default AboutSection;
