import React from 'react';

const PricingAndCheckout = () => {
  const styles = {
    section: {
      padding: '20px',
      backgroundColor: '#f9f9f9',
    },
    container: {
      maxWidth: '800px',
      margin: '0 auto',
    },
    heading1: {
      color: '#333',
    },
    heading2: {
      color: '#333',
    },
    paragraph: {
      color: '#666',
      lineHeight: '1.6',
    },
    priceItem: {
      border: '1px solid #ddd',
      padding: '10px',
      margin: '10px 0',
      borderRadius: '5px',
      backgroundColor: '#fff',
    },
    contactDetails: {
      color: '#333',
      lineHeight: '1.6',
      marginTop: '20px',
    },
  };

  return (
    <div>
      <div style={styles.section}>
        <div style={styles.container}>
          <h1 style={styles.heading1}>Pricing</h1>

          <div style={styles.priceItem}>
            <h2 style={styles.heading2}>Basic Service</h2>
            <p style={styles.paragraph}>Includes basic maintenance and inspection.</p>
          </div>

          <div style={styles.priceItem}>
            <h2 style={styles.heading2}>Standard Service</h2>
            <p style={styles.paragraph}>Includes basic maintenance, oil change, and tire rotation.</p>
          </div>

          <div style={styles.priceItem}>
            <h2 style={styles.heading2}>Premium Service</h2>
            <p style={styles.paragraph}>Includes all standard services plus detailed inspection and diagnostics.</p>
          </div>
        </div>
      </div>

      <div style={styles.section}>
        <div style={styles.container}>
          <h1 style={styles.heading1}>Checkout Process</h1>
          <p style={styles.paragraph}>
            Our checkout process is designed to be simple and efficient. Once you select a service, our team will take
            care of the rest. We strive to provide a seamless experience from the moment you choose your service to the
            completion of your automotive needs. For more details, you can contact us using the information below.
          </p>
        </div>
      </div>

      <div style={styles.section}>
        <div style={styles.container}>
          <h1 style={styles.heading1}>Contact Us</h1>
          <p style={styles.contactDetails}>
            For more details, contact Siragirivel Automobiles at:<br />
            Phone: 099433 08008<br />
            Address: 330/1, Erode to Perundurai Main Road, Post, Vallipurathanpalayam, Erode, Tamil Nadu 638112
          </p>
        </div>
      </div>
    </div>
  );
};

export default PricingAndCheckout;
