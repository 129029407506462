import React from 'react';
import { Link } from 'react-router-dom';
import "./GeneralService.css";
import engineDiagnosticsImage from "./engine.jpg"; // Import the image
import Services from '../Services';

class EngineDiagnosticsDetail extends React.Component {
  render() {
    return (
      <div className="general-service-container">
        <img src={engineDiagnosticsImage} alt="Engine Diagnostics Service" className="general-service-image" />
        <h1 className="general-service-title">Engine Diagnostics Service</h1>
        <p className="general-service-text">
          Our engine diagnostics service helps identify and resolve issues with your vehicle's engine, ensuring optimal performance and reliability. Some key aspects of our engine diagnostics service include:
        </p>
    
        <h3>Using diagnostic tools to retrieve error codes</h3>
        <h3>Testing engine sensors and components</h3>
        <h3>Inspecting ignition and fuel systems</h3>
        <h3>Checking exhaust emissions</h3>
        <h3>Performing compression tests</h3>
        <h3>And more...</h3>
      
        <p className="general-service-text">
          Our skilled technicians analyze diagnostic data to pinpoint engine problems accurately, providing you with effective solutions and peace of mind.
        </p>
        <h2 className="general-service-subtitle">Why Engine Diagnostics is Important</h2>
        <p className="general-service-text">
          Engine diagnostics help prevent engine damage, improve fuel efficiency, and reduce emissions. Early detection and resolution of issues also prevent costly repairs and breakdowns.
        </p>
        <Link to="/Bookservice">
          <button className="general-service-button">Book Engine Diagnostics</button>
        </Link>
        
        <Services />
      </div>
    );
  }
}

export default EngineDiagnosticsDetail;
