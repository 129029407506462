import React from 'react';
import firebase from './firebase';
import "./booking.css";
import LoadingAnimation from './LoadingAnimation'; // Import your loading animation component

class App extends React.Component {
    state = {
        mobile: '',
        otp: '',
        vehicleNumber: '',
        brand: '',
        model: '',
        pickupOption: '',
        address: '',
        isOTPSent: false, // Add state variable for OTP sent message
        isSubmitting: false, // Add state variable for form submission loading
        isVerifyingOTP: false // Add state variable for OTP verification loading
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    };

    configureCaptcha = () => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                this.onSignInSubmit();
                
            },
            defaultCountry: "IN"
        });
    };

    onSignInSubmit = (e) => {
        e.preventDefault();
        this.setState({ isSubmitting: true }); // Start form submission loading
        this.configureCaptcha();
        const phoneNumber = "+91" + this.state.mobile;
        const appVerifier = window.recaptchaVerifier;
        firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                
                // Set state to show OTP sent message
                this.setState({ isOTPSent: true, isSubmitting: false }); // Stop form submission loading
            }).catch((error) => {
               
                this.setState({ isSubmitting: false }); // Stop form submission loading on error
            });
    };

    onSubmitOTP = (e) => {
        e.preventDefault();
        this.setState({ isVerifyingOTP: true }); // Start OTP verification loading
        const code = this.state.otp;
        window.confirmationResult.confirm(code)
            .then((result) => {
                const user = result.user;
                console.log(JSON.stringify(user));
                // Call function to save order details to Firebase
                this.saveOrderToFirebase();
                // Clear the form fields after successful verification
                this.setState({
                    mobile: '',
                    otp: '',
                    vehicleNumber: '',
                    brand: '',
                    model: '',
                    pickupOption: '',
                    address: '',
                    isOTPSent: false, // Reset OTP sent message state
                    isVerifyingOTP: false // Stop OTP verification loading
                });
                alert("Successfully Booked Appointment...");
                // Refresh the page after successful OTP verification
              
            }).catch((error) => {
             
                this.setState({ isVerifyingOTP: false }); // Stop OTP verification loading on error
            });
    };

    saveOrderToFirebase = () => {
        // Get the current date
        const currentDate = new Date().toISOString().slice(0, 10); // Format: YYYY-MM-DD
        
        // Save order details to Firebase Realtime Database
        const orderDetails = {
            mobile: this.state.mobile,
            vehicleNumber: this.state.vehicleNumber,
            brand: this.state.brand,
            model: this.state.model,
            pickupOption: this.state.pickupOption,
            address: this.state.address,
            dateOfBooking: currentDate // Include the booking date
        };
    
        // Assuming 'orders' is your Firebase Realtime Database reference
        firebase.database().ref('orders').push(orderDetails)
            .then(() => {
                console.log("Booking Confirmed");
            })
            .catch((error) => {
                console.error("Error saving order details:", error);
            });
    };

    render() {
        const { isSubmitting, isVerifyingOTP } = this.state;
    
        return (
            <div className="app-container container">
                <div className="booking-system">
                    <h2>Book a Service</h2>
                    <form className="vehicle-form">
                        <input type="text" name="vehicleNumber" placeholder="Vehicle Number" required onChange={this.handleChange} />
                        <select name="brand" onChange={this.handleChange} required>
                            <option value="">Select Brand</option>
                            <option value="Maruti">Maruti Suzuki</option>
                            <option value="Hyundai">Hyundai</option>
                            <option value="Tata">Tata Motors</option>
                            <option value="Mahindra">Mahindra</option>
                            <option value="Honda">Honda</option>
                            <option value="Toyota">Toyota</option>
                            <option value="Kia">Kia</option>
                            <option value="Ford">Ford</option>
                            <option value="Volkswagen">Volkswagen</option>
                            {/* Add more Indian car brands as needed */}
                        </select>
                        <select name="model" onChange={this.handleChange} required>
                            <option value="">Select Model</option>
                            {this.state.brand === "Maruti" && (
                                <>
                                    <option value="Alto">Alto</option>
                                    <option value="800">800</option>
                                    <option value="Swift">Swift</option>
                                    <option value="Baleno">Baleno</option>
                                    <option value="Wagon R">Wagon R</option>
                                    <option value="Dzire">Dzire</option>
                                    <option value="Ertiga">Ertiga</option>
                                    <option value="Vitara Brezza">Vitara Brezza</option>
                                    <option value="Celerio">Celerio</option>
                                    <option value="S-Presso">S-Presso</option>
                                    <option value="Ignis">Ignis</option>
                                    {/* Add more models for Maruti Suzuki */}
                                </>
                            )}
                            {this.state.brand === "Hyundai" && (
                                <>
                                    <option value="Grand i10">Grand i10</option>
                                    <option value="Venue">Venue</option>
                                    <option value="Creta">Creta</option>
                                    <option value="Santro">Santro</option>
                                    <option value="Elite i20">Elite i20</option>
                                    <option value="Verna">Verna</option>
                                    <option value="Aura">Aura</option>
                                    <option value="Tucson">Tucson</option>
                                    <option value="Kona Electric">Kona Electric</option>
                                    <option value="Alcazar">Alcazar</option>
                                    {/* Add more models for Hyundai */}
                                </>
                            )}
                            {this.state.brand === "Tata" && (
                                <>
                                    <option value="Tiago">Tiago</option>
                                    <option value="Nexon">Nexon</option>
                                    <option value="Harrier">Harrier</option>
                                    <option value="Altroz">Altroz</option>
                                    <option value="Tigor">Tigor</option>
                                    <option value="Safari">Safari</option>
                                    <option value="Nano">Nano</option> {/* Discontinued */}
                                    <option value="Indica">Indica</option> {/* Discontinued */}
                                    <option value="Indigo">Indigo</option> {/* Discontinued */}
                                    {/* Add more models for Tata Motors */}
                                </>
                            )}
                            {this.state.brand === "Mahindra" && (
                                <>
                                    <option value="XUV300">XUV300</option>
                                    <option value="Scorpio">Scorpio</option>
                                    <option value="Thar">Thar</option>
                                    <option value="Bolero">Bolero</option>
                                    <option value="XUV500">XUV500</option>
                                    <option value="KUV100">KUV100</option>
                                    <option value="Marazzo">Marazzo</option>
                                    <option value="TUV300">TUV300</option>
                                    <option value="Alturas G4">Alturas G4</option>
                                    {/* Add more models for Mahindra */}
                                </>
                            )}
                            {this.state.brand === "Honda" && (
                                <>
                                    <option value="City">City</option>
                                    <option value="Amaze">Amaze</option>
                                    <option value="Jazz">Jazz</option>
                                    <option value="WR-V">WR-V</option>
                                    <option value="Civic">Civic</option>
                                    <option value="CR-V">CR-V</option>
                                    {/* Add more models for Honda */}
                                </>
                            )}
                            {this.state.brand === "Toyota" && (
                                <>
                                    <option value="Innova Crysta">Innova Crysta</option>
                                    <option value="Fortuner">Fortuner</option>
                                    <option value="Glanza">Glanza</option>
                                    <option value="Yaris">Yaris</option>
                                    <option value="Camry">Camry</option>
                                    <option value="Urban Cruiser">Urban Cruiser</option>
                                    {/* Add more models for Toyota */}
                                </>
                            )}
                            {this.state.brand === "Kia" && (
                                <>
                                    <option value="Seltos">Seltos</option>
                                    <option value="Sonet">Sonet</option>
                                    <option value="Carnival">Carnival</option>
                                    {/* Add more models for Kia */}
                                </>
                            )}
                            {this.state.brand === "Ford" && (
                                <>
                                    <option value="EcoSport">EcoSport</option>
                                    <option value="Figo">Figo</option>
                                    <option value="Endeavour">Endeavour</option>
                                    <option value="Aspire">Aspire</option>
                                    <option value="Feista">Feista</option>
                                    {/* Add more models for Ford */}
                                </>
                            )}
                            {this.state.brand === "Volkswagen" && (
                                <>
                                    <option value="Polo">Polo</option>
                                    <option value="Vento">Vento</option>
                                    <option value="Taigun">Taigun</option>
                                    <option value="T-Roc">T-Roc</option>
                                    <option value="Tiguan Allspace">Tiguan Allspace</option>
                                    {/* Add more models for Volkswagen */}
                                </>
                            )}
                            {/* Add more Indian car brand models as needed */}
                        </select>
                        <select name="pickupOption" onChange={this.handleChange} required>
                            <option value="">Select Pickup Option</option>
                            <option value="self">Self Drop</option>
                            <option value="doorstep">Doorstep Pickup</option>
                        </select>
                        {this.state.pickupOption === 'doorstep' && (
                            <div>
                                <h2>Enter Address</h2>
                                <input type="text" name="address" placeholder="Enter Address" value={this.state.address} onChange={this.handleChange} />
                            </div>
                        )}
                    </form>
                
                    <form className="phone-verification-form" onSubmit={this.onSignInSubmit}>
                        <div id="sign-in-button"></div>
                        <input type="number" name="mobile" placeholder="Mobile number" required onChange={this.handleChange} />
                        <button type="submit" disabled={isSubmitting}>Submit</button>
                        {isSubmitting && <LoadingAnimation />} {/* Render loading animation */}
                    </form>
                
                    {this.state.isOTPSent && <p>OTP has been sent</p>}
                    
                    <h2>Enter OTP</h2>
                    <form className="otp-form" onSubmit={this.onSubmitOTP}>
                        <input type="number" name="otp" placeholder="OTP Number" required onChange={this.handleChange} />
                        <button type="submit" disabled={isVerifyingOTP}>Submit</button>
                        {isVerifyingOTP && <LoadingAnimation />} {/* Render loading animation */}
                    </form>
                </div>
                <div className="background-image"></div> {/* This div holds your background image */}
            </div>
        );
    }
    
}

export default App;
