import React from 'react';
import { Link } from 'react-router-dom';
import "./GeneralService.css";
import carImage from "./genser.jpg"; // Import the image
import Services from '../Services';

class VehicleServicingDetail extends React.Component {
  render() {
    return (
      <div className="general-service-container">
        <img src={carImage} alt="Car" className="general-service-image" />
        <h1 className="general-service-title">Vehicle Servicing</h1>
        <p className="general-service-text">
          Our vehicle servicing includes a comprehensive check-up of your vehicle to ensure it's running smoothly and safely. Some of the key aspects of our vehicle servicing include:
        </p>
    
        <ul className="general-service-list">
          <li>Oil change using high-quality oils</li>
          <li>Filter replacements (air, oil, fuel)</li>
          <li>Fluid top-ups (coolant, brake fluid, power steering fluid)</li>
          <li>Visual inspection of belts, hoses, and other components</li>
          <li>Brake inspection</li>
          <li>Tire pressure and tread depth check</li>
          <li>And more...</li>
        </ul>
      
        <p className="general-service-text">
          Our skilled technicians ensure that your vehicle receives the care it deserves, helping to prolong its lifespan and maintain optimal performance.
        </p>
        <h2 className="general-service-subtitle">Why Regular Servicing is Important</h2>
        <p className="general-service-text">
          Regular servicing helps in identifying potential issues early on, preventing costly repairs and ensuring your safety on the road. It also helps maintain the resale value of your vehicle.
        </p>
        <Link to="/Bookservice">
          <button className="general-service-button">Book Appointment</button>
        </Link>
        

        <Services />
      </div>
    );
  }
}

export default VehicleServicingDetail;
