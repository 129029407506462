import React from 'react';
import { Link } from 'react-router-dom';
import "./GeneralService.css";
import brakeSystemImage from "./braking.jpg"; // Import the image
import Services from '../Services';

class BrakeSystemDetail extends React.Component {
  render() {
    return (
      <div className="general-service-container">
        <img src={brakeSystemImage} alt="Brake System Service" className="general-service-image" />
        <h1 className="general-service-title">Brake System Service</h1>
        <p className="general-service-text">
          Our brake system service ensures that your vehicle's brakes are in top condition, providing reliable stopping power and safety. Some key aspects of our brake system service include:
        </p>
    
        <h3>Inspection of brake pads, rotors, and calipers</h3>
        <h3>Brake fluid flush and replacement</h3>
        <h3>Adjustment of brake components</h3>
        <h3>Checking brake lines for leaks and damage</h3>
        <h3>Testing brake performance</h3>
        <h3>And more...</h3>
      
        <p className="general-service-text">
          Our certified technicians ensure that your brake system operates effectively, providing you with confidence and peace of mind while driving.
        </p>
        <h2 className="general-service-subtitle">Why Brake System Service is Important</h2>
        <p className="general-service-text">
          Regular brake system maintenance prevents brake failure, reduces stopping distance, and ensures vehicle safety. It also helps avoid costly repairs and extends the lifespan of brake components.
        </p>
        <Link to="/Bookservice">
          <button className="general-service-button">Book Brake Service</button>
        </Link>
     
        <Services />
      </div>
    );
  }
}

export default BrakeSystemDetail;
