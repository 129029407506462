import React from 'react';

const PrivacyPolicy = () => {
  const styles = {
    privacyPolicy: {
      padding: '20px',
      backgroundColor: '#f9f9f9',
    },
    container: {
      maxWidth: '800px',
      margin: '0 auto',
    },
    heading1: {
      color: '#333',
    },
    heading2: {
      color: '#333',
    },
    paragraph: {
      color: '#666',
      lineHeight: '1.6',
    },
  };

  return (
    <div style={styles.privacyPolicy}>
      <div style={styles.container}>
        <h1 style={styles.heading1}>Privacy Policy</h1>
        <p style={styles.paragraph}>
          At Siragirivel Automobiles, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services.
        </p>

        <h2 style={styles.heading2}>1. Information We Collect</h2>
        <p style={styles.paragraph}>
          We may collect personal information that you voluntarily provide to us when you register on our site, place an order, subscribe to our newsletter, or fill out a form. This information may include your name, email address, phone number, and payment information.
        </p>

        <h2 style={styles.heading2}>2. How We Use Your Information</h2>
        <p style={styles.paragraph}>
          We use the information we collect from you to process transactions, send periodic emails, improve our website, and personalize your experience. Your information helps us to respond to your customer service requests and support needs more efficiently.
        </p>

        <h2 style={styles.heading2}>3. Information Sharing</h2>
        <p style={styles.paragraph}>
          We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.
        </p>

        <h2 style={styles.heading2}>4. Security</h2>
        <p style={styles.paragraph}>
          We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information. However, please be aware that no method of transmission over the Internet, or method of electronic storage, is 100% secure.
        </p>

        <h2 style={styles.heading2}>5. Cookies</h2>
        <p style={styles.paragraph}>
          We use cookies to enhance your experience on our site. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information.
        </p>

        <h2 style={styles.heading2}>6. Changes to This Privacy Policy</h2>
        <p style={styles.paragraph}>
          Siragirivel Automobiles reserves the right to update or change our Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page. Your continued use of the service after we post any modifications to the Privacy Policy will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.
        </p>

        <h2 style={styles.heading2}>7. Contact Us</h2>
        <p style={styles.paragraph}>
          If you have any questions about this Privacy Policy, please contact us at admin@siragirivel.in .
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
