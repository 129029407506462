import React from 'react';

const Footer = () => {
  return (
    <footer>
      <p>&copy; 2024 Siragirivel Automobiles. All rights reserved. | 
        <a href="/terms-and-conditions"> Terms and Conditions</a> | <a href="/privacy-policy"> Privacy Policy</a> | <a href="/Refund-policy">Refund Policy</a>
      </p>
    </footer>
  );
};

export default Footer;
