import React from 'react';
import { Link } from 'react-router-dom';
import "./GeneralService.css";
import electricalSystemImage from "./electrical.jpg"; // Import the image
import Services from '../Services';

class ElectricalSystemDetail extends React.Component {
  render() {
    return (
      <div className="general-service-container">
        <img src={electricalSystemImage} alt="Electrical System Service" className="general-service-image" />
        <h1 className="general-service-title">Electrical System Service</h1>
        <p className="general-service-text">
          Our electrical system service ensures that your vehicle's electrical components are functioning properly, providing reliable performance and safety. Some key aspects of our electrical system service include:
        </p>
    
        <h3>Testing and diagnosing electrical issues</h3>
        <h3>Inspecting and repairing wiring and connections</h3>
        <h3>Checking and replacing fuses and relays</h3>
        <h3>Testing battery and alternator performance</h3>
        <h3>Diagnosing and repairing dashboard warning lights</h3>
        <h3>And more...</h3>
      
        <p className="general-service-text">
          Our experienced technicians ensure that your vehicle's electrical system operates smoothly, providing you with a comfortable and safe driving experience.
        </p>
        <h2 className="general-service-subtitle">Why Electrical System Service is Important</h2>
        <p className="general-service-text">
          Proper maintenance of the electrical system prevents electrical failures, ensures proper operation of critical components, and enhances vehicle safety. It also prevents breakdowns and costly repairs.
        </p>
        <Link to="/Bookservice">
          <button className="general-service-button">Book Electrical Service</button>
        </Link>
       
        <Services />
      </div>
    );
  }
}

export default ElectricalSystemDetail;
