import React from 'react';
import './PriceList.css';

const services = [
  { name: 'General Check', price: 1499 },
  { name: 'AC Service', price: 1800 },
  { name: 'General Service', price: 2999 },
  { name: 'Single Panel Painting', price: 2500 },
  { name: 'Full Body Painting', price: 30000 },
];

const PriceList = () => {
  return (
    <div className="price-list">
      <h1>Service Prices - starting from</h1>
      <ul>
        {services.map((service, index) => (
          <li key={index}>
            <span>{service.name}</span>
            <span>{service.price.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PriceList;
