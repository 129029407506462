import React from 'react';
import { Link } from 'react-router-dom';
import "./GeneralService.css";
import wheelAlignmentImage from "./wheel.jpg"; // Import the image
import Services from '../Services';

class WheelAlignmentDetail extends React.Component {
  render() {
    return (
      <div className="general-service-container">
        <img src={wheelAlignmentImage} alt="Wheel Alignment Service" className="general-service-image" />
        <h1 className="general-service-title">Wheel Alignment Service</h1>
        <p className="general-service-text">
          Our wheel alignment service ensures that your vehicle's wheels are properly aligned, improving handling, tire wear, and overall safety. Some key aspects of our wheel alignment service include:
        </p>
    
        <h3>Inspecting and adjusting wheel angles</h3>
        <h3>Checking and correcting caster, camber, and toe angles</h3>
        <h3>Checking tire pressure and tread depth</h3>
        <h3>Inspecting suspension components</h3>
        <h3>Test-driving to ensure proper alignment</h3>
        <h3>And more...</h3>
      
        <p className="general-service-text">
          Our experienced technicians use advanced equipment to ensure precise wheel alignment, providing you with a smoother and safer driving experience.
        </p>
        <h2 className="general-service-subtitle">Why Wheel Alignment is Important</h2>
        <p className="general-service-text">
          Proper wheel alignment improves fuel efficiency, extends tire life, and enhances vehicle stability and safety. It also prevents premature wear and tear on steering and suspension components.
        </p>
        <Link to="/Bookservice">
          <button className="general-service-button">Book Wheel Alignment</button>
        </Link>
       
        <Services />
      </div>
    );
  }
}

export default WheelAlignmentDetail;
